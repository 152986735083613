<template>
  <div>
    <!-- Татан авах шаардлагатай барааны жагсаалт -->
    <el-dialog
        title="Татан авах шаардлагатай барааны жагсаалт"
        :visible.sync="show"
        width="700px"
        @close="closeFinishDialog">
      <el-table
        :data="products"
        v-loading='isLoading'
        style="width: 100%"
        height="500"
      >
        <el-table-column label="Зураг" align="center">
          <template slot-scope="scope">
            <el-row :gutter="10" align="middle" type="flex">
              <el-col :span="12">
                <div class="image-holder">
                  <img :src="scope.row.image[0]">
                </div>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="Нэр" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.name_mon}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Нийлүүлэгч" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.supplier_mon_name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Тоо, ширхэг" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.product_count}}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">Болих</el-button>
      </span>
      </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'getProductDialog',
  props: [
    'visibleProductDialog',
    'products',
    'isLoading'
  ],

  data () {
    return {
      show: this.visibleProductDialog
    }
  },

  methods: {
    closeFinishDialog () {
      this.$emit('update:visibleProductDialog', false)
    }
  }
}
</script>

<style>
</style>
