<template>
  <div>
    <!-- Product list table ued zaaval product-table gesen class uguh -->
    <el-table
      v-loading="isLoading"
      :data="transfers"
      :show-header="false"
      style="width: 100%"
    >
      <el-table-column>
        <template slot-scope="scope">
          <router-link :to="
          scope.row.transfer_status === 'pending' ?
          { name: 'editTransfer', params: { transfer_id: scope.row.transfer_id }}
          :
          { name: 'transferDetail', params: { transfer_id: scope.row.transfer_id }}
          ">
            <strong>#{{ scope.row.transfer_id }}</strong>
            <span class="ml20"
                >{{ scope.row.transfer_name }}</span
              >
            <div
              v-if="scope.row.expected_arrival_at"
              class="text-color-secondary"
            >
              Expected at {{ scope.row.expected_arrival_at }}
            </div>
            <div v-else class="text-color-secondary">
              Expected at Огноо сонгогдоогүй
            </div>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.transfer_status === 'pending'"
            type="warning"
            effect="dark"
            size="small"
            >{{ scope.row.transfer_status }}</el-tag
          >
          <el-tag
            v-if="scope.row.transfer_status === 'partial'"
            type="warning"
            effect="dark"
            size="small"
            >{{ scope.row.transfer_status }}</el-tag
          >
          <el-tag
            v-if="scope.row.transfer_status === 'complete'"
            type="success"
            effect="dark"
            size="small"
            >{{ scope.row.transfer_status }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope">
          <progress-bar
          :accepted="scope.row.accepted"
          :canceled="scope.row.canceled"
          :rejected="scope.row.rejected"
          :total="scope.row.number_tobe_transferred"
          ></progress-bar>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page.sync="page"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>
<script>
import ProgressBar from './progressBar.vue'
export default {
  name: 'transferTable',
  components: {
    ProgressBar
  },
  props: [
    'transfers',
    'pageSize',
    'totalCount',
    'currentPage',
    'curentPageHandler',
    'sizeChangeHandler',
    'isLoading'
  ],
  watch: {
    currentPage (lastData) {
      this.page = lastData
    }
  },
  data () {
    return {
      page: 1
    }
  }
}
</script>
