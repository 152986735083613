<template>
  <div>
    <!-- Бүх хуудсанд ашиглагдах header section -->
    <el-row :gutter="0">
      <el-col :span="20" :offset="2">
        <header class="page-header">
          <el-row :gutter="20" align="middle">
            <el-col :span="12">
              <h2>Татан авалтын жагсаалт</h2>
            </el-col>
            <!-- <el-col :span="12">
              <div class="actions text-right">
                  <el-button type="default" @click="visibleProductDialog = true">Татан авах шаардлагатай бараа</el-button>
                <router-link to="/add-transfer">
                  <el-button type="success" class="item">Нэмэх</el-button>
                </router-link>
              </div>
            </el-col> -->
          </el-row>
        </header>
        <!-- Tabled content -->
        <div class="panel tabled">
          <el-tabs v-model="activeTabStatus" @tab-click="onClickTab">
            <el-tab-pane label="All" name="all">
              <div class="panel-content">
                <div class="pl20 pr20 table-filter mb10">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="20">
                      <el-input placeholder="Хайх" class="input-with-select" v-model="searchText" @keyup.enter.native="searchTransfer()">
                        <el-button
                          slot="prepend"
                          icon="el-icon-search"
                          @click="searchTransfer()"
                        ></el-button>
                      </el-input>
                    </el-col>
                  </el-row>
                </div>
                <transfer-table :transfers="transfers" :isLoading="isLoading" :pageSize="pageSize" :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></transfer-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Pending" name="pending">
              <div class="panel-content">
                <div class="pl20 pr20 table-filter mb10">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="20">
                      <el-input placeholder="Хайх" class="input-with-select" v-model="searchText" @keyup.enter.native="searchTransfer()">
                        <el-button
                          slot="prepend"
                          icon="el-icon-search"
                          @click="searchTransfer()"
                        ></el-button>
                      </el-input>
                    </el-col>
                    <el-col :span="4" :offset="4" class="text-right">
                      <el-dropdown @command="supplierSort">
                        <el-button type="default" class="lowercase"
                          >Эрэмбэлэх <i class="el-icon-sort"></i
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command = 'created'
                            >Үүсгэгдсэн</el-dropdown-item
                          >
                          <el-dropdown-item command = 'supplier_processing'
                            >Бэлтгэж байгаа</el-dropdown-item
                          >
                          <el-dropdown-item command = 'supplier_complete'
                            >Бэлтгэгдсэн</el-dropdown-item
                          >
                          <el-dropdown-item command = 'driver_in'
                            >Жолоочид хувиарлагдсан</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-col>
                  </el-row>
                </div>
                <transfer-table :transfers="transfers" :isLoading="isLoading" :pageSize="pageSize" :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></transfer-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Partial" name="partial">
              <div class="panel-content">
                <div class="pl20 pr20 table-filter mb10">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="20">
                      <el-input placeholder="Хайх" class="input-with-select" v-model="searchText" @keyup.enter.native="searchTransfer()">
                        <el-button
                          slot="prepend"
                          icon="el-icon-search"
                          @click="searchTransfer()"
                        ></el-button>
                      </el-input>
                    </el-col>
                    <el-col :span="4" :offset="4" class="text-right">
                      <el-dropdown>
                        <el-button type="default" class="lowercase"
                          >Эрэмбэлэх <i class="el-icon-sort"></i
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            >Product title A - Z</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Producvt title Z - A</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Created newest first</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Created oldest first</el-dropdown-item
                          >
                          <el-dropdown-item>Low inventory</el-dropdown-item>
                          <el-dropdown-item>High inventory</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-col>
                  </el-row>
                </div>
                <transfer-table :transfers="transfers" :isLoading="isLoading" :pageSize="pageSize" :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></transfer-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Completed" name="complete">
              <div class="panel-content">
                <div class="pl20 pr20 table-filter mb10">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="20">
                      <el-input placeholder="Хайх" class="input-with-select" v-model="searchText" @keyup.enter.native="searchTransfer()">
                        <el-button
                          slot="prepend"
                          icon="el-icon-search"
                          @click="searchTransfer()"
                        ></el-button>
                      </el-input>
                    </el-col>
                    <el-col :span="4" :offset="4" class="text-right">
                      <el-dropdown>
                        <el-button type="default" class="lowercase"
                          >Эрэмбэлэх <i class="el-icon-sort"></i
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            >Product title A - Z</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Producvt title Z - A</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Created newest first</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Created oldest first</el-dropdown-item
                          >
                          <el-dropdown-item>Low inventory</el-dropdown-item>
                          <el-dropdown-item>High inventory</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-col>
                  </el-row>
                </div>
                <transfer-table :transfers="transfers" :isLoading="isLoading" :pageSize="pageSize" :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></transfer-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <get-product-dialog :products='products' v-if="visibleProductDialog" :isLoading='isLoading' :visible-product-dialog.sync="visibleProductDialog"></get-product-dialog>
  </div>
</template>

<script>
// // @ is an alias to /src
import getProductDialog from './components/getProductDialog'
import services from '../../../helpers/services'
import TransferTable from './components/transferTable'
import { getSupplierId } from '../../../utils/auth'
export default {
  name: 'transferList',
  components: {
    // MenuIcon
    TransferTable,
    // eslint-disable-next-line vue/no-unused-components
    getProductDialog
  },
  data () {
    return {
      supplierIds: null,
      totalCount: 0,
      currentPage: 1,
      products: [],
      visibleProductDialog: false,
      pageSize: 20,
      searchText: '',
      search: '',
      status: '',
      tableData: null,
      transfers: [],
      isLoading: false,
      activeTabStatus: 'all'
    }
  },
  async mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size && this.$route.query.search && this.$route.query.tab) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingTab = this.$route.query.tab
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      this.tab = incomingTab
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getTransfersBySupplierId(await getSupplierId(), size, from)
  },

  methods: {
    async supplierSort (data) {
      this.getTransfersBySupplierId(await getSupplierId(), this.pageSize, this.pageSize * (this.currentPage - 1), this.searchText, data)
    },
    async  onClickTab (data) {
      if (data.name === 'all') {
        this.status = ''
      } else if (data.name === 'pending') {
        this.status = 'pending'
      } else if (data.name === 'partial') {
        this.status = 'partial'
      } else if (data.name === 'complete') {
        this.status = 'complete'
      }
      this.getTransfersBySupplierId(await getSupplierId(), this.pageSize, this.pageSize * (this.currentPage - 1))
    },
    // async onSearch () {
    //   this.$router.push({ name: 'transfer', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab } })
    //   this.getTransfers(this.pageSize * (this.currentPage - 1), this.pageSize, this.search)
    // },
    async sizeChangeHandler (size) {
      this.pageSize = size
      this.$router.push({ name: 'transfer', query: { page: this.currentPage, size: size } })
      this.getTransfersBySupplierId(await getSupplierId(), this.pageSize, this.pageSize * (this.currentPage - 1), this.search)
    },

    async curentPageHandler (current) {
      this.currentPage = current
      this.$router.push({ name: 'transfer', query: { page: current, size: this.pageSize } }).catch((error) => {
        console.log(error)
      })
      this.getTransfersBySupplierId(await getSupplierId(), this.pageSize, this.pageSize * (this.currentPage - 1), this.search)
    },

    async getTransfersBySupplierId (supplierIds, size, from, search, data) {
      this.isLoading = true
      const body = {
        supplier_ids: supplierIds,
        size: size,
        from: from,
        transfer_status: this.status,
        supplier_status: data,
        search_text: search
      }
      services.getTransfersBySupplierId(body).then(response => {
        this.isLoading = false
        this.transfers = response.data
        this.totalCount = response.total
      })
    },

    getAllRequiredProduct () {
      this.isLoading = true
      services.getAllRequiredProduct().then(response => {
        this.products = response.data
        this.isLoading = false
      })
    },

    async searchTransfer () {
      this.getTransfersBySupplierId(await getSupplierId(), this.pageSize, this.pageSize * (this.currentPage - 1), this.searchText)
    }
  }
}
</script>
<style>
.el-table .cell {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
